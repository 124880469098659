import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Gavel
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTQgMTMtNy41IDcuNWMtLjgzLjgzLTIuMTcuODMtMyAwIDAgMCAwIDAgMCAwYTIuMTIgMi4xMiAwIDAgMSAwLTNMMTEgMTAiIC8+CiAgPHBhdGggZD0ibTE2IDE2IDYtNiIgLz4KICA8cGF0aCBkPSJtOCA4IDYtNiIgLz4KICA8cGF0aCBkPSJtOSA3IDggOCIgLz4KICA8cGF0aCBkPSJtMjEgMTEtOC04IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/gavel
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Gavel = createLucideIcon('Gavel', [
  [
    'path',
    {
      d: 'm14 13-7.5 7.5c-.83.83-2.17.83-3 0 0 0 0 0 0 0a2.12 2.12 0 0 1 0-3L11 10',
      key: 'c9cbz0',
    },
  ],
  ['path', { d: 'm16 16 6-6', key: 'vzrcl6' }],
  ['path', { d: 'm8 8 6-6', key: '18bi4p' }],
  ['path', { d: 'm9 7 8 8', key: '5jnvq1' }],
  ['path', { d: 'm21 11-8-8', key: 'z4y7zo' }],
]);

export default Gavel;
